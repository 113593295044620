import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { StateManager } from '../../utilities/stateManager.js';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import firebase from '../../utilities/firebase';
const db = firebase.firestore();

export default function Invoice({ order }) {
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [partMargins, setPartMargins] = useState({});
  const [laborRate, setLaborRate] = useState(125); // Default labor rate
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [taxEnabled, setTaxEnabled] = useState(true);
  const [invalidMargins, setInvalidMargins] = useState(false);
  const [shopSuppliesFee, setShopSuppliesFee] = useState(0);
  const [wasteFee, setWasteFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [editingPartId, setEditingPartId] = useState(null);
  const [editedServiceNames, setEditedServiceNames] = useState({});
  const [editedPartMemos, setEditedPartMemos] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  const DEFAULT_MARGIN = 25; // 25% default margin
  const TAX_RATE = order.customer.id === "9c0d88f5-84f9-454d-833d-a8ced9adad49" ? 0 : 0.06; // Changed to 6%

  // Initialize selections and margins when order data changes
  useEffect(() => {
    if (order.services) {
      setSelectedServices(order.services);
    }
    if (order.expenses) {
      setSelectedParts(order.expenses);
      
      const initialMargins = {};
      order.expenses.forEach(part => {
        initialMargins[part.id] = partMargins[part.id] || DEFAULT_MARGIN;
      });
      setPartMargins(initialMargins);
    }
  }, [order.services, order.expenses]);

  const handleMarginChange = (partId, newValue) => {
    const newMargins = {
      ...partMargins,
      [partId]: newValue
    };
    setPartMargins(newMargins);
    
    // Check if any margins are invalid (empty or null)
    const hasInvalidMargins = order.expenses?.some(part => {
      const margin = newMargins[part.id];
      return margin === null || margin === undefined || margin === '';
    });
    setInvalidMargins(hasInvalidMargins);
  };

  const calculatePartPrice = (part) => {
    const cost = part.amount || 0;
    const margin = partMargins[part.id];
    
    if (margin === null || margin === undefined || margin === '') {
      return 0;
    }
    
    // New formula: Price = Cost * (1 + margin/100)
    // This allows for margins over 100%
    return cost * (1 + margin / 100);
  };

  const calculateAdjustedSubtotal = () => {
    return subtotal + shopSuppliesFee + wasteFee - discount;
  };

  const calculateTotalPayments = () => {
    return (order.deposits || [])
      .filter(deposit => deposit.type === 'service')
      .reduce((sum, deposit) => sum + (deposit.amount || 0), 0);
  };

  useEffect(() => {
    calculateTotals();
  }, [selectedServices, selectedParts, laborRate, taxEnabled, shopSuppliesFee, wasteFee, discount]);

  const calculateTotals = () => {
    const servicesTotal = selectedServices.reduce((sum, service) => {
      const hours = service.hours || 1;
      return sum + (hours * laborRate);
    }, 0);
    
    const partsTotal = selectedParts.reduce((sum, part) => 
      sum + (part.price * (part.quantity || 1) || 0), 0
    );
    
    const newSubtotal = servicesTotal + partsTotal;
    const adjustedSubtotal = calculateAdjustedSubtotal();
    const newTax = taxEnabled ? adjustedSubtotal * TAX_RATE : 0;
    
    setSubtotal(newSubtotal);
    setTax(newTax);
    setTotal(adjustedSubtotal + newTax);
  };

  const handleServiceToggle = (service) => {
    const currentIndex = selectedServices.findIndex(s => s.id === service.id);
    const newSelected = [...selectedServices];

    if (currentIndex === -1) {
      newSelected.push(service);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedServices(newSelected);
  };

  const handlePartToggle = (part) => {
    const currentIndex = selectedParts.findIndex(p => p.id === part.id);
    const newSelected = [...selectedParts];

    if (currentIndex === -1) {
      newSelected.push(part);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedParts(newSelected);
  };

  const formatInputValue = (value) => {
    // Remove leading zeros and return empty string if 0
    if (value === 0) return '';
    return value.toString();
  };

  const handleFieldEdit = async (type, id, newValue) => {
    const config = {
      service: {
        collection: 'services',
        field: 'name',
        orderField: 'services',
        setState: setEditedServiceNames,
        displayName: 'service description'
      },
      part: {
        collection: 'purchases',
        field: 'memo',
        orderField: 'expenses',
        setState: setEditedPartMemos,
        displayName: 'part description'
      }
    };

    const { collection, field, orderField, setState, displayName } = config[type];

    // Update local state
    setState(prev => ({
      ...prev,
      [id]: newValue
    }));

    try {
      // Update Firebase record using db
      await db.doc(`${collection}/${id}`).update({ [field]: newValue });
      
      // Update order object
      const updatedItems = order[orderField].map(item => 
        item.id === id ? { ...item, [field]: newValue } : item
      );
      StateManager.updateOrder({[orderField]: updatedItems});

    } catch (error) {
      console.error(`Error updating ${type}:`, error);
      setSnackbar({
        open: true,
        message: `Failed to update ${displayName}: ${error.message}`,
        severity: 'error'
      });
      
      setState(prev => ({
        ...prev,
        [id]: order[orderField].find(item => item.id === id)?.[field]
      }));
    }
  };

  // Simplified handlers that use the shared function
  const handleServiceNameEdit = (serviceId, newName) => {
    handleFieldEdit('service', serviceId, newName);
  };

  const handlePartMemoEdit = (partId, newMemo) => {
    handleFieldEdit('part', partId, newMemo);
  };

  const handleKeyDown = (e, resetFunction) => {
    if (e.key === 'Escape') {
      resetFunction();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Invoice for Order #{order.stock}
      </Typography>
      
      {/* Labor Rate Input */}
      <TextField
        label="Labor Rate"
        type="number"
        value={laborRate}
        onChange={(e) => setLaborRate(Number(e.target.value))}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
        }}
        sx={{ mb: 2 }}
      />
      
      {/* Customer Information */}
      <Typography variant="h6" gutterBottom>
        Customer: {order.customer?.display_name || 'N/A'}
      </Typography>

      {/* Services Section with updated property name */}
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ p: 1 }}>Services</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Include</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Hours</TableCell>
              <TableCell align="right">Rate</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.services?.map((service) => (
              <TableRow key={service.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedServices.some(s => s.id === service.id)}
                    onChange={() => handleServiceToggle(service)}
                  />
                </TableCell>
                <TableCell>
                  {editingServiceId === service.id ? (
                    <TextField
                      fullWidth
                      value={editedServiceNames[service.id] || service.name}
                      onChange={(e) => handleServiceNameEdit(service.id, e.target.value)}
                      onBlur={() => setEditingServiceId(null)}
                      onKeyDown={(e) => handleKeyDown(e, () => setEditingServiceId(null))}
                      error={!editedServiceNames[service.id] && !service.name}
                      helperText={(!editedServiceNames[service.id] && !service.name) ? "Description cannot be empty" : ""}
                      autoFocus
                    />
                  ) : (
                    <Box
                      onClick={() => {
                        setEditingServiceId(service.id);
                        if (!editedServiceNames[service.id]) {
                          handleServiceNameEdit(service.id, service.name);
                        }
                      }}
                      sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'action.hover' }, p: 1 }}
                    >
                      {editedServiceNames[service.id] || service.name}
                    </Box>
                  )}
                </TableCell>
                <TableCell align="right">{service.hours || 1}</TableCell>
                <TableCell align="right">${laborRate.toFixed(2)}</TableCell>
                <TableCell align="right">
                  ${((service.hours || 1) * laborRate).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Updated Parts Section */}
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ p: 1 }}>Parts</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Include</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Cost</TableCell>
              <TableCell align="right">Margin %</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.expenses?.map((part) => {
              const cost = part.amount || 0;
              const price = calculatePartPrice(part);
              const quantity = part.quantity || 1;
              const margin = partMargins[part.id];
              const isInvalidMargin = margin === null || margin === undefined || margin === '';

              return (
                <TableRow key={part.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedParts.some(p => p.id === part.id)}
                      onChange={() => handlePartToggle(part)}
                    />
                  </TableCell>
                  <TableCell>
                    {editingPartId === part.id ? (
                      <TextField
                        fullWidth
                        value={editedPartMemos[part.id] || part.memo}
                        onChange={(e) => handlePartMemoEdit(part.id, e.target.value)}
                        onBlur={() => setEditingPartId(null)}
                        onKeyDown={(e) => handleKeyDown(e, () => setEditingPartId(null))}
                        error={!editedPartMemos[part.id] && !part.memo}
                        helperText={(!editedPartMemos[part.id] && !part.memo) ? "Description cannot be empty" : ""}
                        autoFocus
                      />
                    ) : (
                      <Box
                        onClick={() => {
                          setEditingPartId(part.id);
                          if (!editedPartMemos[part.id]) {
                            handlePartMemoEdit(part.id, part.memo);
                          }
                        }}
                        sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'action.hover' }, p: 1 }}
                      >
                        {editedPartMemos[part.id] || part.memo}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="right">{quantity}</TableCell>
                  <TableCell align="right">${cost.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={margin}
                      onChange={(e) => handleMarginChange(part.id, e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      size="small"
                      sx={{ 
                        width: '100px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: isInvalidMargin ? 'error.main' : 'inherit',
                          },
                        },
                      }}
                      error={isInvalidMargin}
                    />
                  </TableCell>
                  <TableCell align="right">
                    ${(price * quantity).toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Updated Totals Section */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="body1" align="right">
          Subtotal: ${subtotal.toFixed(2)}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2, mb: 1 }}>
          <Typography variant="body1" align="right" sx={{ minWidth: '200px' }}>
            *Shop Supplies Fee:
          </Typography>
          <TextField
            type="number"
            value={formatInputValue(shopSuppliesFee)}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Number(e.target.value);
              setShopSuppliesFee(value);
            }}
            placeholder="0"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            size="small"
            sx={{ 
              width: '150px',
              '& input': { textAlign: 'right' }
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2, mb: 1 }}>
          <Typography variant="body1" align="right" sx={{ minWidth: '200px' }}>
            *Waste Fee/Tire Fee:
          </Typography>
          <TextField
            type="number"
            value={formatInputValue(wasteFee)}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Number(e.target.value);
              setWasteFee(value);
            }}
            placeholder="0"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            size="small"
            sx={{ 
              width: '150px',
              '& input': { textAlign: 'right' }
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2, mb: 1 }}>
          <Typography variant="body1" align="right" sx={{ minWidth: '200px' }}>
            Discount:
          </Typography>
          <TextField
            type="number"
            value={formatInputValue(discount)}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Number(e.target.value);
              setDiscount(value);
            }}
            placeholder="0"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            size="small"
            sx={{ 
              width: '150px',
              '& input': { textAlign: 'right' }
            }}
          />
        </Box>

        <Typography variant="body1" align="right" sx={{ fontWeight: 'bold' }}>
          Adjusted Subtotal: ${calculateAdjustedSubtotal().toFixed(2)}
        </Typography>

        <Typography variant="body1" align="right">
          Tax Rate: {(TAX_RATE * 100).toFixed(1)}%
        </Typography>

        <Typography variant="body1" align="right">
          Total Tax: ${tax.toFixed(2)}
        </Typography>

        <Typography variant="body1" align="right" color="error">
          Payments: -${calculateTotalPayments().toFixed(2)}
        </Typography>

        <Typography variant="h6" align="right" sx={{ fontWeight: 'bold', mt: 2 }}>
          Balance Due: ${(total - calculateTotalPayments()).toFixed(2)}
        </Typography>
      </Paper>

      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 2 }}
        disabled={invalidMargins}
        onClick={() => {
          console.log('Generate Invoice', {
            services: selectedServices,
            parts: selectedParts,
            subtotal,
            tax,
            total
          });
        }}
      >
        Generate Invoice
      </Button>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
